'use client';

import React, { PropsWithChildren, useMemo } from 'react';

interface PageContextValue {
    seoContext: string;
}

export const PageContext = React.createContext<PageContextValue>({
    seoContext: '',
});

export const PageProvider = ({ children, seoContext }: PropsWithChildren<PageContextValue>) => {
    const value = useMemo(() => {
        return {
            seoContext,
        };
    }, [seoContext]);

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};
